.credit-card-widget {
	.primary-gradient-card {
		border-color:$black !important;
	}
}
.credit-card-widget{
    .primary-gradient-card{
        background:linear-gradient(117.76deg, rgba(000, 000, 000, 0.5) -7.21%, rgba(000, 000, 000, 0.01) 118.08%);
	}
}
