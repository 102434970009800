&.boxed {
    background: var(--#{$variable-prefix}primary) !important;
    .boxed-inner {
        background: $body-bg;
    }
}
&.boxed-fancy {
    background: $body-bg;
    .boxed-inner{
        background: $body-bg; 
    }
}