.profile-media {
    &::before {
        border-color: $border-color;
    }
}
.profile-dots-pills {
    background-color: $card-bg;
}
.profile-story-img{
    filter: invert(100%);
}